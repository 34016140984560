import { css } from '@emotion/css';

import { materialIconStyles } from 'site-react/components/styles/materialIcon.styles';
import theme from 'site-react/theme';

export const innerStyle = css`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: space-between;
  gap: 16px;

  @media (min-width: 768px) {
    align-items: center;
    flex-wrap: unset;
  }
`;

export const facilitiesStyle = css`
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const truncatedTextStyle = css`
  width: 200px;

  p {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  @media (min-width: 768px) {
    width: 300px;
  }
`;

export const facilitiesContentStyle = css`
  padding: var(--space-xxl) var(--space-lg);

  @media (min-width: var(--space-lg)) {
    text-align: center;
  }
`;

export const pricingQuestionStyle = css`
  cursor: pointer;
`;

export const pricingQuestionHeadingStyle = css`
  ${theme.content3};

  &::before {
    ${materialIconStyles};
    font-size: var(--font-size-md);
    top: 2px;
    color: var(--color-brandsecondary);
    content: 'help';
    float: left;
    left: 0;
    position: absolute;
  }

  display: block;
  font-weight: var(--font-weight-medium);
  margin: 0 0 var(--space-xs) 0;
  padding-left: var(--space-lg);
  position: relative;
`;

export const pricingQuestionContentStyle = css`
  list-style: none;

  ::-webkit-details-marker {
    /* Disable default <summary> arrow styling */
    display: none;
  }
`;

export const expandIconStyle = css`
  font: var(--font-style-content-1);
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  line-height: var(--space-lg);

  details[open] &::after {
    content: 'expand_less';
    font-family: 'Material Icons';
    vertical-align: bottom;
  }

  details:not([open]) &::after {
    content: 'expand_more';
    font-family: 'Material Icons';
    vertical-align: bottom;
  }
`;
