import { css, cx, keyframes } from '@emotion/css';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { LinkAsButton, NavBar } from 'site-react/components/navigation';
import buttonStyles from 'site-react/components/styles/button.module.css';
import { MaterialIcon } from 'site-react/components/typography';
import config from 'site-react/config';
import { Context as ShortlistContext } from 'site-react/data/core/ShortlistContext';
import getAppUrl from 'site-react/helpers/getAppUrl';
import useUser from 'site-react/hooks/useUser';

import AccountItem from './components/AccountItem';
import LocationMenuContent from './components/LocationMenuContent';
import ResourcesMenuContent from './components/ResourcesMenuContent';
import SearchMenuContent from './components/SearchMenuContent';
import SolutionsMenuContent from './components/SolutionsMenuContent';
import useLinkWithNextParam from './useLinkWithNextParam';

const accessoryContainerStyle = css`
  display: flex;
  align-items: center;
  gap: var(--space-sm);
`;

const iconContainerStyle = css`
  position: relative;
  width: 24px;
  height: 24px;
`;

const iconStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: 1;
`;

const popIn = keyframes`
  from {
    z-index: 2;
    opacity: 1;
    transform: scale(1);
  }

  40% {
    transform: scale(1.5);
  }

  80% {
    z-index: 2;
    opacity: 1;
  }

  to {
    z-index: 0;
    opacity: 0;
    transform: scale(1);
  }
`;

const animateIconStyle = css`
  color: var(--color-brandprimary);
  animation: ${popIn} 0.5s ease-in-out;
  z-index: 0;
  opacity: 0;
`;

/**
 * The NavBar component has a lot of complex logic to handle the color of the nav
 * item links in various states which clashes a little with the default state of the
 * button colors. This is a quick fix to override the default button color styles
 * for the nav bar.
 */
const buttonColorStyle = css`
  color: var(--color-white);
`;

const visibleBelowLgViewportStyle = css`
  display: block;
  padding: 0 var(--space-sm);
  margin-left: auto;

  @media (min-width: 992px) {
    display: none;
  }
`;

const visibleAboveLgViewportStyle = css`
  display: none;

  @media (min-width: 992px) {
    display: flex;
  }
`;

const SiteNavigation = ({
  activeWorkspace = null,
  darkThemeAnimated = false,
  accessory = null,
}) => {
  const { isLoggedIn, isUserLoading, user } = useUser();
  const { shortlist, animateNavIcon } = useContext(ShortlistContext);

  const loginHref = useLinkWithNextParam('/login');
  const logoutHref = useLinkWithNextParam('/logout', true);

  const isAdmin = user?.role === 'admin';

  const pathname = usePathname();
  const appUrl = getAppUrl(config);

  const urlSource = new URL(pathname, appUrl);
  const getAQuoteUrl = new URL(
    `https://search.hubblehq.com/get-a-quote?utm_source=navigation&utm_medium=platform&url_source=${urlSource.toString()}`,
  );

  const shouldDisplayShortlistIcon = isLoggedIn && shortlist.length > 0;
  const shouldDisplayGetAQuote = !isLoggedIn;

  return (
    <NavBar
      accessory={
        <div className={accessoryContainerStyle}>
          {accessory}
          {shouldDisplayGetAQuote ? (
            <div className={visibleBelowLgViewportStyle}>
              <LinkAsButton
                href={getAQuoteUrl.toString()}
                isCompact
                linkType="OutboundLink"
                name="Get a free shortlist"
              >
                <span className={buttonColorStyle}>Get a free shortlist</span>
              </LinkAsButton>
            </div>
          ) : null}
          {shouldDisplayShortlistIcon && (
            <Link
              className={visibleBelowLgViewportStyle}
              href="/dashboard/hq/shortlist"
              title="My shortlist"
            >
              <div className={iconContainerStyle}>
                <MaterialIcon
                  className={iconStyle}
                  iconType="favorite_border"
                  role="presentation"
                />
                {animateNavIcon && (
                  <MaterialIcon
                    className={cx(iconStyle, animateIconStyle)}
                    iconType="star"
                    role="presentation"
                  />
                )}
              </div>
            </Link>
          )}
        </div>
      }
      darkThemeAnimated={darkThemeAnimated}
      isLogoTextDisplayed={!accessory}
      isUserLoading={isUserLoading}
    >
      {!isLoggedIn && (
        <>
          <NavBar.MenuItem label="Solutions">
            <NavBar.MegaMenu>
              <SolutionsMenuContent />
            </NavBar.MegaMenu>
          </NavBar.MenuItem>
          <NavBar.MenuItem label="Locations">
            <NavBar.MegaMenu>
              <LocationMenuContent />
            </NavBar.MegaMenu>
          </NavBar.MenuItem>
          <NavBar.MenuItem label="Resources">
            <NavBar.MegaMenu>
              <ResourcesMenuContent />
            </NavBar.MegaMenu>
          </NavBar.MenuItem>
          <NavBar.SpacerItem />
        </>
      )}

      <NavBar.MenuItem label="Search">
        <NavBar.MegaMenu>
          <SearchMenuContent activeWorkspace={activeWorkspace} />
        </NavBar.MegaMenu>
      </NavBar.MenuItem>

      {!isLoggedIn && (
        <>
          <NavBar.LinkItem
            className={visibleAboveLgViewportStyle}
            customLabelElement={
              <div
                className={cx(
                  buttonStyles['Button'],
                  buttonStyles['Button--compact'],
                  buttonStyles['Button--primary'],
                )}
              >
                Get a free shortlist
              </div>
            }
            href={getAQuoteUrl.toString()}
            label="Get a free shortlist"
          />
          <NavBar.LinkItem href="/host" label="List space" />
        </>
      )}

      {isLoggedIn && <NavBar.LinkItem href="/dashboard" label="My Hubble" />}

      {shouldDisplayShortlistIcon && (
        <NavBar.LinkItem
          className={visibleAboveLgViewportStyle}
          customLabelElement={
            <div className={iconContainerStyle}>
              <MaterialIcon
                className={iconStyle}
                iconType="favorite_border"
                role="presentation"
              />
              {animateNavIcon && (
                <MaterialIcon
                  className={cx(iconStyle, animateIconStyle)}
                  iconType="star"
                  role="presentation"
                />
              )}
            </div>
          }
          href="/dashboard/hq/shortlist"
          label="My shortlist"
        />
      )}

      {isLoggedIn ? (
        <NavBar.MenuItem
          customLabelElement={<AccountItem>Account</AccountItem>}
          forceOpenInHamburger
          label="Signed In"
          relative
        >
          <NavBar.SimpleMenu>
            <NavBar.SimpleMenu.List>
              <li>
                <Link href="/dashboard/pass/bookings">On-Demand bookings</Link>
              </li>
              {isAdmin ? (
                <li>
                  <Link href="/dashboard/pass">Credits</Link>
                </li>
              ) : null}
              <li>
                <Link href="/dashboard/admin">Profile</Link>
              </li>
              {isAdmin ? (
                <li>
                  <Link href="/dashboard/admin/team">Team</Link>
                </li>
              ) : null}
              <li>
                <Link href={logoutHref}>Logout</Link>
              </li>
            </NavBar.SimpleMenu.List>
          </NavBar.SimpleMenu>
        </NavBar.MenuItem>
      ) : (
        <NavBar.MenuItem forceOpenInHamburger label="Login" relative>
          <NavBar.SimpleMenu>
            <NavBar.SimpleMenu.List>
              <li>
                <Link
                  data-testid="SiteNavigation-customerLogin"
                  href={loginHref}
                >
                  Login
                </Link>
              </li>
              <li>
                <Link
                  href={`/login?next=${encodeURIComponent('/host/listings')}`}
                >
                  Host login
                </Link>
              </li>
            </NavBar.SimpleMenu.List>
          </NavBar.SimpleMenu>
        </NavBar.MenuItem>
      )}
    </NavBar>
  );
};

SiteNavigation.propTypes = {
  /**
   * An accessory to display in available NavBar whitespace.
   */
  accessory: PropTypes.node,

  /**
   * Which workspace nav item should have active styling applied, if any
   */
  activeWorkspace: PropTypes.oneOf([
    'hq-private-offices',
    'part-time-offices',
    'pass-coworking',
    'pass-meeting-room',
    'pass-private-office',
    null,
  ]),

  /**
   * Whether it changes color on scroll
   */
  darkThemeAnimated: PropTypes.bool,
};

export default SiteNavigation;
